.box-la {
    margin-bottom: 0.5rem;
    padding: 0.7rem;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0.4rem;
}

.grid {
    margin-bottom: 0.5rem;
    padding: 0.7rem;
    background-color: rgba(0, 0, 0, 0.1);
    min-height: 20vh;
    border-radius: 0.4rem;
}

.box-la:last-child, .grid:last-child {
    margin-bottom: 0;
}

.login-panel {
    margin: 17vh auto 40vh;
    width: 20rem;
    text-align: center;
}
