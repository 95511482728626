.company-vm {
    margin-bottom: 0.7rem;
    padding: 0.7rem;
    background-color: #fffff0;
    line-height: 1.5;
    border-radius: 0.4rem;
}

.company-title {
    padding: 0.6rem 0;
    font-size: 1.2rem;
}

.company-label {
    font-weight: bold;
}
.company-label:after {
    content: "：";
}

.company-description {
    margin-top: 0.5rem;
    color: #333;
    font-size: 0.9rem;
}
