.customer-support-vm {
    margin-bottom: 0.7rem;
    padding: 0.7rem;
    background-color: #fffff0;
    line-height: 1.5rem;
    border-radius: 0.4rem;
}
.customer-support-vm:last-child {
    margin-bottom: 0;
}

.customer-support-label {
    font-weight: bold;
}
.customer-support-label:after {
    content: "：";
}

.customer-support-question {
    hyphens: auto;
    overflow-wrap: break-word;
}
