.subscription-vm {
    margin-bottom: 0.7rem;
    padding: 0.7rem;
    background-color: #fffff0;
    line-height: 1.5rem;
    border-radius: 0.4rem;
}
.subscription-vm:last-child {
    margin-bottom: 0;
}

.subscription-label {
    font-weight: bold;
}
.subscription-label:after {
    content: "：";
}
