.text-input {
    display: flex;
    margin-bottom: 0.7rem;
    width: 100%;
    align-items: center;
}

.text-input-label, .text-input-field {
    padding: 0.5rem;
    font-size: 1.2rem;
}

.text-input-label {
    flex-grow: 0;
    font-weight: bolder;
    text-align: right;
}

.text-input-field {
    flex-grow: 2;
    border: 0;
    border-radius: 0.3rem;
}