.table-row {
    text-align: center;
}

.table-cell-head,
.table-cell {
    font-size: 0.8rem;
}
.table-cell > .ellipsis {
    margin: 0 auto;
    width: 20vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
